import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import { SUSPENSE_FALLBACK_ELEMENT } from 'src/globals';

const AdminLogin = React.lazy(() => import('./views/auth/AdminLogin'));
const AdminLayout = React.lazy(() => import('./containers/admin/Layout'));
const MainLayout = React.lazy(() => import('./containers/MainLayout'));
const Page401 = React.lazy(() => import('./views/pages/error-pages/Page401'));
const Page404 = React.lazy(() => import('./views/pages/error-pages/Page404'));
const Page500 = React.lazy(() => import('./views/pages/error-pages/Page500'));

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <React.Suspense fallback={SUSPENSE_FALLBACK_ELEMENT}>
                    <Switch>                        
                        <Route exact path="/admin/401" name="Error 401 - Unauthorized" render={props => <Page401 {...props} />} />
                        <Route exact path="/admin/404" name="Error 404 - Page Not Found" render={props => <Page404 {...props} />} />
                        <Route exact path="/admin/500" name="Error 500 - Internal Server Error" render={props => <Page500 {...props} />} />
                        <Route exact path="/admin/login" name="Administrator Console Login" render={props => <AdminLogin {...props} />} />
                        <Route path="/admin" name="Administrator Console" render={props => <AdminLayout {...props} />} />
                        
                        <Route exact path="/401" name="Error 401 - Unauthorized" render={props => <Page401 {...props} />} />
                        <Route exact path="/404" name="Error 404 - Page Not Found" render={props => <Page404 {...props} />} />
                        <Route exact path="/500" name="Error 500 - Internal Server Error" render={props => <Page500 {...props} />} />
                        <Route path="/" name="Main Layout" render={props => <MainLayout {...props} />} />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        );
    }
}

export default App;
