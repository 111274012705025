export const REQUEST_EMAIL_TOKEN = 'REQUEST_EMAIL_TOKEN'
export const REQUEST_EMAIL_TOKEN_INDICATOR =  'REQUEST_EMAIL_TOKEN_INDICATOR'

export const VERIFY_EMAIL_TOKEN = 'VERIFY_EMAIL_TOKEN'
export const VERIFY_EMAIL_TOKEN_INDICATOR =  'VERIFY_EMAIL_TOKEN_INDICATOR'

export const REQUEST_PHONE_TOKEN = 'REQUEST_PHONE_TOKEN'
export const REQUEST_PHONE_TOKEN_INDICATOR =  'REQUEST_PHONE_TOKEN_INDICATOR'

export const VERIFY_PHONE_TOKEN = 'VERIFY_PHONE_TOKEN'
export const VERIFY_PHONE_TOKEN_INDICATOR =  'VERIFY_PHONE_TOKEN_INDICATOR'

export const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA'
export const SET_REGISTRATION_DATA_INDICATOR =  'SET_REGISTRATION_DATA_INDICATOR'

export const SET_DELETED_ORDER_REGISTRATION_FILE = 'SET_DELETED_ORDER_REGISTRATION_FILE'
export const SET_DELETE_ORDER_REGISTRATION_FILE_INDICATOR= 'SET_DELETE_ORDER_REGISTRATION_FILE_INDICATOR'

export const SUMBMIT_REGISTRATION = 'SUMBMIT_REGISTRATION'
export const SUMBMIT_REGISTRATION_INDICATOR =  'SUMBMIT_REGISTRATION_INDICATOR'
	 	 

