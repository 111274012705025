// import { Cookies } from 'js-cookie';

export const compare = ( a, b ) => {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
} 

export const global_getGreetingTime = (m) => {
    // console.log(m);
    var g = null; //return g

    if(!m || !m.isValid()) { return; } //if we can't find a valid or filled moment, we return.

    var split_afternoon = 12 //24hr time to split the afternoon
    var split_evening = 17 //24hr time to split the evening
    var currentHour = parseFloat(m.format("HH"));

    if(currentHour >= split_afternoon && currentHour <= split_evening) {
        g = "afternoon";
    } else if(currentHour >= split_evening) {
        g = "evening";
    } else {
        g = "morning";
    }

    return g;
}

export const indicatorFormatter = (value) => {
    // console.log("typeof value");
    // console.log(typeof value);
    // console.log(value);

    if(value === undefined ) {
        return {
            active: value, 
            code: 0,
            message: "Internal Server Error",
            data: []
        }
    }


    if(typeof value === "boolean" ) {
        return {
            active: value, 
            code: 0,
            message: "",
            data: []
        };
    }

    // // SUCCESS // October 25, 2021
    // if(value.status !== undefined) {
        
    //     if( value.status === 401) {
    //         // console.log("logging out")
    //         // clearUserCookie();
    //         window.location.href = '/logout'; 
    //     }
    //     return {
    //         active: false, 
    //         code: value.status,
    //         message: value.statusText,
    //         data: value.data.data
    //     };
    // }

    // // ERROR
    // if(value.response !== undefined && value.response.status !== undefined) {
       
    //     if( value.response.status === 401) {
    //         // console.log("logging out")
    //         // clearUserCookie();
    //         window.location.href = '/logout'; 
    //     }
    //     return {
    //         active: false, 
    //         code: value.response.status,
    //         message: value.response.statusText,
    //         data: value.response.data.data
    //     };
    // } 

// ANYTHING ELSE
    return { 
        active: false, 
        code: 0,
        message: "",
        data: []
    };

}

export const currencyFormatter = ( n = 0, c = '$', d = 1) => {
    if(isNumeric(n)) {
        var parts = parseFloat(n).toFixed(d).split(".");
        var num = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + 
            (parts[1] ? "." + parts[1] : "");
        // console.log("decimal: " + d + " --- " + n + " = " + (c+''+num));
        return c+''+num;
    } else {
        // console.log("none numeric: " + n + " = " + (c+''+num));
        return c+'0.0';
    }
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const clearUserCookie = () => {    
    // Cookies.remove('USER');
    // Cookies.remove('USER_TOKEN');

    // console.log("clearUserCookie");
    // console.log(Cookies);
}

export const toTitleCase = (str) => {
    // console.log("toTitleCase")
    // console.log(str)
    return str.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

export const trimString = (str) => {
    return str.replace(/^\s+|\s+$/g, '');
}