import {
    ADMIN_GET_SITES,
    ADMIN_GET_SITES_INDICATOR,
    ADMIN_ADD_SITE,
    ADMIN_ADD_SITE_INDICATOR,
    ADMIN_GET_SITE,
    ADMIN_GET_SITE_INDICATOR,
    ADMIN_UPDATE_SITE,
    ADMIN_UPDATE_SITE_INDICATOR,
    ADMIN_DELETE_SITE,
    ADMIN_DELETE_SITE_INDICATOR,
    ADMIN_GET_SITE_REGISTRATIONS,
    ADMIN_GET_SITE_REGISTRATIONS_INDICATOR,
    ADMIN_GET_SITE_APPOINTMENTS,
    ADMIN_GET_SITE_APPOINTMENTS_INDICATOR,
    ADMIN_GET_CC_SITES,
    ADMIN_GET_CC_SITES_INDICATOR,
    ADMIN_GET_CC_SERVICE_TYPES,
    ADMIN_GET_CC_SERVICE_TYPES_INDICATOR

    
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    sites: [],
    getSitesIndicator: defaultIndicatorValue,
    addedSite: {},
    addSiteIndicator: defaultIndicatorValue,
    selectedSite: {},
    getSiteIndicator: defaultIndicatorValue,
    updatedSite: {},
    updateSiteIndicator: defaultIndicatorValue,
    deletedSite: {},
    deleteSiteIndicator: defaultIndicatorValue,
    siteRegistrations: [],
    getSiteRegistrationsIndicator: defaultIndicatorValue,
    siteAppointments: [],
    getSiteAppointmentsIndicator: defaultIndicatorValue,
    ccSites : [],
    getCCSitesIndicator: defaultIndicatorValue,
    ccServiceTypes : [],
    ccServiceTypesIndicator: defaultIndicatorValue,

}

const admin_sites = (state = initialState, action) => {
    switch (action.type) {

        case ADMIN_GET_SITES:
            return { ...state, sites: action.payload }

        case ADMIN_GET_SITES_INDICATOR:
            return { ...state, getSitesIndicator: action.payload }

        case ADMIN_ADD_SITE:
            return { ...state, addedSite: action.payload }

        case ADMIN_ADD_SITE_INDICATOR:
            return { ...state, addSiteIndicator: action.payload }

        case ADMIN_GET_SITE:
            return { ...state, selectedSite: action.payload }

        case ADMIN_GET_SITE_INDICATOR:
            return { ...state, getSiteIndicator: action.payload }

        case ADMIN_UPDATE_SITE:
            return { ...state, updatedSite: action.payload }

        case ADMIN_UPDATE_SITE_INDICATOR:
            return { ...state, updateSiteIndicator: action.payload }

        case ADMIN_DELETE_SITE:
            return { ...state, deletedSite: action.payload }

        case ADMIN_DELETE_SITE_INDICATOR:
            return { ...state, deleteSiteIndicator: action.payload }

        case ADMIN_GET_SITE_REGISTRATIONS:
            console.log("ADMIN_GET_SITE_REGISTRATIONS action.payload")
            console.log(action.payload)
            return { ...state, siteRegistrations: action.payload }

        case ADMIN_GET_SITE_REGISTRATIONS_INDICATOR:
            return { ...state, getSiteRegistrationsIndicator: action.payload }

        case ADMIN_GET_SITE_APPOINTMENTS:
            return { ...state, siteAppointments: action.payload }

        case ADMIN_GET_SITE_APPOINTMENTS_INDICATOR:
            return { ...state, getSiteAppointmentsIndicator: action.payload }

        case ADMIN_GET_CC_SITES:
            return { ...state, ccSites: action.payload }

        case ADMIN_GET_CC_SITES_INDICATOR:
            return { ...state, getCCSitesIndicator: action.payload }

        case ADMIN_GET_CC_SERVICE_TYPES:
            return { ...state, ccServiceTypes: action.payload }

        case ADMIN_GET_CC_SERVICE_TYPES_INDICATOR:
            return { ...state, ccServiceTypesIndicator: action.payload }           

        default:
            return state;
    }
}

export default admin_sites