import {
    VERIFY_SITE,
    VERIFY_SITE_INDICATOR,
    GET_ALL_SITES,
    GET_ALL_SITES_INDICATOR,
    ADD_SITE,
    ADD_SITE_INDICATOR,
    GET_SITE,
    GET_SITE_INDICATOR,
    UPDATE_SITE,
    UPDATE_SITE_INDICATOR,
    DELETE_SITE,
    DELETE_SITE_INDICATOR,
} from "../constants/action-types"

var initialState = {
    verifySite: false,
    verifySiteIndicator: { active: false, code: 0, data: [] },
    allSites: [],
    getAllSitesIndicator: { active: false, code: 0, data: [] },
    addedSite: {},
    addSiteIndicator: { active: false, code: 0, data: [] },
    selectedSite: {},
    getSiteIndicator: { active: false, code: 0, data: [] },
    updatedSite: {},
    updateSiteIndicator: { active: false, code: 0, data: [] },
    deletedSite: {},
    deleteSiteIndicator: { active: false, code: 0, data: [] },
}

const sites = (state = initialState, action) => {
    switch (action.type) {

        case VERIFY_SITE:
            return { ...state, verifySite: action.payload }

        case VERIFY_SITE_INDICATOR:
            return { ...state, verifySiteIndicator: action.payload }

        case GET_ALL_SITES:
            return { ...state, allSites: action.payload }

        case GET_ALL_SITES_INDICATOR:
            return { ...state, getAllSitesIndicator: action.payload }

        case ADD_SITE:
            return { ...state, addedSite: action.payload }

        case ADD_SITE_INDICATOR:
            return { ...state, addSiteIndicator: action.payload }

        case GET_SITE:
            return { ...state, selectedSite: action.payload }

        case GET_SITE_INDICATOR:
            return { ...state, getSiteIndicator: action.payload }

        case UPDATE_SITE:
            return { ...state, updatedSite: action.payload }

        case UPDATE_SITE_INDICATOR:
            return { ...state, updateSiteIndicator: action.payload }

        case DELETE_SITE:
            return { ...state, deletedSite: action.payload }

        case DELETE_SITE_INDICATOR:
            return { ...state, deleteSiteIndicator: action.payload }

        default:
            return state;
    }
}

export default sites