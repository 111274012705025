import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    VERIFY_SITE,
    VERIFY_SITE_INDICATOR,
    GET_ALL_SITES,
    GET_ALL_SITES_INDICATOR,
    ADD_SITE,
    ADD_SITE_INDICATOR,
    GET_SITE,
    GET_SITE_INDICATOR,
    UPDATE_SITE,
    UPDATE_SITE_INDICATOR,
    DELETE_SITE,
    DELETE_SITE_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _verifySite = data => ({ type: VERIFY_SITE, payload: data })
export const _verifySiteIndicator = value => ({ type: VERIFY_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const _getAllSites = data => ({ type: GET_ALL_SITES, payload: data })
export const _getAllSitesIndicator = value => ({ type: GET_ALL_SITES_INDICATOR, payload: indicatorFormatter(value) })

export const _addSite = data => ({ type: ADD_SITE, payload: data })
export const _addSiteIndicator = value => ({ type: ADD_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const _getSite = data => ({ type: GET_SITE, payload: data })
export const _getSiteIndicator = value => ({ type: GET_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const _updateSite = data => ({ type: UPDATE_SITE, payload: data })
export const _updateSiteIndicator = value => ({ type: UPDATE_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const _deleteSite = data => ({ type: DELETE_SITE, payload: data })
export const _deleteSiteIndicator = value => ({ type: DELETE_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const verifySite = (site_id, order_key, form_type = "registration") => {
    return (dispatch) => {
        dispatch(_verifySiteIndicator(true));
        return axios.get(API_SERVER + '/api/sites/' + site_id + "/" + form_type + "?order_key=" + order_key, {
            headers: {
                "Accept": "application/json",
            }
        }).then(response => {
            dispatch(_verifySiteIndicator(response))
            dispatch(_verifySite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_verifySiteIndicator(error))
            dispatch(_verifySite(false))
            return { result: "failed", error }
        })
    }
}

export const getAllSites = () => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getAllSitesIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites', {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("getAllSites success")
            // console.log(response)
            dispatch(_getAllSitesIndicator(response))
            dispatch(_getAllSites(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("getAllSites error")
            // console.log(error)
            dispatch(_getAllSitesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const addSite = (payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_addSiteIndicator(true));
        return axios.post(API_SERVER + '/api/admin/sites', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("add Site success")
            // console.log(response)
            dispatch(_addSiteIndicator(response))
            dispatch(_addSite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("add Site error")
            // console.log(error)
            dispatch(_addSiteIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const getSite = (site_slug) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getSiteIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_slug, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("getSite success")
            // console.log(response)
            dispatch(_getSiteIndicator(response))
            dispatch(_getSite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("getSite error")
            // console.log(error)
            dispatch(_getSiteIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const updateSite = (site_slug, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_updateSiteIndicator(true));
        return axios.put(API_SERVER + '/api/admin/sites/' + site_slug, payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("add Site success")
            // console.log(response)
            dispatch(_updateSiteIndicator(response))
            dispatch(_updateSite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("add Site error")
            // console.log(error)
            dispatch(_updateSiteIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const deleteSite = (site_slug) => {
    const cookies = new Cookies()
    
    return (dispatch) => {
        dispatch(_deleteSiteIndicator(true));
        return axios.delete(API_SERVER + '/api/admin/sites/' + site_slug, {
            "headers": {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_deleteSiteIndicator(response))
            dispatch(_deleteSite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_deleteSiteIndicator(error))
            return { result: "failed", error }
        })
    }
}