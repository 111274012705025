import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    ADMIN_GET_SITE_ORDERS,
    ADMIN_GET_SITE_ORDERS_INDICATOR,
    // ADMIN_ADD_SITE_ORDER,
    // ADMIN_ADD_SITE_ORDER_INDICATOR,
    ADMIN_GET_SITE_ORDER,
    ADMIN_GET_SITE_ORDER_INDICATOR,
    ADMIN_UPDATE_SITE_ORDER,
    ADMIN_UPDATE_SITE_ORDER_INDICATOR,
    // ADMIN_DELETE_SITE_ORDER,
    // ADMIN_DELETE_SITE_ORDER_INDICATOR,

} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

// use for the experimental method
// import {
    // _adminUpdateForm,
    // _adminUpdateFormIndicator,
    // _adminUpdateFormHeaderImage,
    // _adminUpdateFormHeaderImageIndicator,
// } from './admin_sites_forms'

export const _adminGetSiteOrders = data => ({ type: ADMIN_GET_SITE_ORDERS, payload: data })
export const _adminGetSiteOrdersIndicator = value => ({ type: ADMIN_GET_SITE_ORDERS_INDICATOR, payload: indicatorFormatter(value) })

// export const _adminAddSiteOrder = data => ({ type: ADMIN_ADD_SITE_ORDER, payload: data })
// export const _adminAddSiteOrderIndicator = value => ({ type: ADMIN_ADD_SITE_ORDER_INDICATOR, payload: indicatorFormatter(value) })

export const _adminGetSiteOrder = data => ({ type: ADMIN_GET_SITE_ORDER, payload: data })
export const _adminGetSiteOrderIndicator = value => ({ type: ADMIN_GET_SITE_ORDER_INDICATOR, payload: indicatorFormatter(value) })

export const _adminUpdateSiteOrder = data => ({ type: ADMIN_UPDATE_SITE_ORDER, payload: data })
export const _adminUpdateSiteOrderIndicator = value => ({ type: ADMIN_UPDATE_SITE_ORDER_INDICATOR, payload: indicatorFormatter(value) })

// export const _adminDeleteSiteOrder = data => ({ type: ADMIN_DELETE_SITE_ORDER, payload: data })
// export const _adminDeleteSiteOrderIndicator = value => ({ type: ADMIN_DELETE_SITE_ORDER_INDICATOR, payload: indicatorFormatter(value) })

export const adminGetSiteOrders = (site_key, pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetSiteOrdersIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_key + '/orders', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log("adminGetSiteOrders success")
            console.log(response)
            dispatch(_adminGetSiteOrdersIndicator(response))
            dispatch(_adminGetSiteOrders(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log("getAllSiteOrders error")
            console.log(error)
            dispatch(_adminGetSiteOrdersIndicator(error))
            return { result: "failed", error }
        })
    }
}

// export const adminAddSiteOrder = (payload) => {
//     const cookies = new Cookies()

//     return (dispatch) => {
//         dispatch(_adminAddSiteOrderIndicator(true));
//         return axios.post(API_SERVER + '/api/admin/sites', payload, {
//             headers: {
//                 "Accept": "application/json",
//                 "Authorization": "Bearer " + cookies.get('user_token')
//             }
//         }).then(response => {
//             // console.log("add SiteOrder success")
//             // console.log(response)
//             dispatch(_adminAddSiteOrderIndicator(response))
//             dispatch(_adminAddSiteOrder(response.data))
//             return { result: "success", data: response.data }
//         }).catch((error) => {
//             // console.log("add SiteOrder error")
//             // console.log(error)
//             dispatch(_adminAddSiteOrderIndicator(error))
//             return { result: "failed", error }
//         })
//     }
// }

export const adminGetSiteOrder = (site_key, order_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetSiteOrderIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_key + '/orders/' + order_id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("adminGetSiteOrder success")
            // console.log(response)
            dispatch(_adminGetSiteOrderIndicator(response))
            dispatch(_adminGetSiteOrder(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminGetSiteOrderIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminUpdateSiteOrder = (site_key, order_id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminUpdateSiteOrderIndicator(true));
        return axios.put(API_SERVER + '/api/admin/sites/' + site_key + '/orders/' + order_id, payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminUpdateSiteOrderIndicator(response))
            dispatch(_adminUpdateSiteOrder(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminUpdateSiteOrderIndicator(error))
            return { result: "failed", error }
        })
    }
}

// export const adminDeleteSiteOrder = (site_slug) => {
//     const cookies = new Cookies()

//     return (dispatch) => {
//         dispatch(_adminDeleteSiteOrderIndicator(true));
//         return axios.delete(API_SERVER + '/api/admin/sites/' + site_slug, {
//             "headers": {
//                 "Accept": "application/json",
//                 "Authorization": "Bearer " + cookies.get('user_token')
//             }
//         }).then(response => {
//             dispatch(_adminDeleteSiteOrderIndicator(response))
//             dispatch(_adminDeleteSiteOrder(response.data))
//             return { result: "success", data: response.data }
//         }).catch((error) => {
//             dispatch(_adminDeleteSiteOrderIndicator(error))
//             return { result: "failed", error }
//         })
//     }
// }
