
import {
    /** Phone */
    REQUEST_PHONE_TOKEN,
    REQUEST_PHONE_TOKEN_INDICATOR,
    VERIFY_PHONE_TOKEN,
    VERIFY_PHONE_TOKEN_INDICATOR,
    /** Email */
    REQUEST_EMAIL_TOKEN,
    REQUEST_EMAIL_TOKEN_INDICATOR,
    VERIFY_EMAIL_TOKEN,
    VERIFY_EMAIL_TOKEN_INDICATOR,
    SET_REGISTRATION_DATA,
    SET_REGISTRATION_DATA_INDICATOR,
    SET_DELETED_ORDER_REGISTRATION_FILE,
    SET_DELETE_ORDER_REGISTRATION_FILE_INDICATOR,
    /** Submit */
    SUMBMIT_REGISTRATION,
    SUMBMIT_REGISTRATION_INDICATOR
} from "../constants/action-types"


var initialState = {
    requestPhoneToken: false,
    requestPhoneTokenIndicator: false,
    verifyPhoneToken: false,
    verifyPhoneTokenIndicator: false,
    requestEmailToken: false,
    requestEmailTokenIndicator: false,
    verifyEmailToken: false,
    verifyEmailTokenIndicator: false,
    registrationData: {},
    registrationDataIndicator: false,
    deletedOrderRegistrationFile: {},
    deleteOrderRegistrationFileIndicator: false,
    submitRegistration: false,
    submitRegistrationIndicator: false
}

const registrations = (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_PHONE_TOKEN:
            return { ...state, requestPhoneToken: action.payload }

        case REQUEST_PHONE_TOKEN_INDICATOR:
            return { ...state, requestPhoneTokenIndicator: action.payload }

        case VERIFY_PHONE_TOKEN:
            return { ...state, verifyPhoneToken: action.payload }

        case VERIFY_PHONE_TOKEN_INDICATOR:
            return { ...state, verifyPhoneTokenIndicator: action.payload }

        case REQUEST_EMAIL_TOKEN:
            return { ...state, requestEmailToken: action.payload }

        case REQUEST_EMAIL_TOKEN_INDICATOR:
            return { ...state, requestEmailTokenIndicator: action.payload }

        case VERIFY_EMAIL_TOKEN:
            return { ...state, verifyEmailToken: action.payload }

        case VERIFY_EMAIL_TOKEN_INDICATOR:
            return { ...state, verifyEmailTokenIndicator: action.payload }

        case SET_REGISTRATION_DATA:
            return { ...state, registrationData: action.payload }

        case SET_REGISTRATION_DATA_INDICATOR:
            return { ...state, registrationDataIndicator: action.payload }

        case SET_DELETED_ORDER_REGISTRATION_FILE:
            return { ...state, deletedOrderRegistrationFile: action.payload }

        case SET_DELETE_ORDER_REGISTRATION_FILE_INDICATOR:
            return { ...state, deleteOrderRegistrationFileIndicator: action.payload }

        case SUMBMIT_REGISTRATION:
            return { ...state, submitRegistration: action.payload }

        case SUMBMIT_REGISTRATION_INDICATOR:
            return { ...state, submitRegistrationIndicator: action.payload }

        default:
            return state
    }
}

export default registrations