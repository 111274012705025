import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    ADMIN_GET_USERS,
    ADMIN_GET_USERS_INDICATOR,
    ADMIN_ADD_USER,
    ADMIN_ADD_USER_INDICATOR,
    ADMIN_GET_USER,
    ADMIN_GET_USER_INDICATOR,
    ADMIN_UPDATE_USER,
    ADMIN_UPDATE_USER_INDICATOR,
    ADMIN_DELETE_USER,
    ADMIN_DELETE_USER_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _adminGetUsers = data => ({ type: ADMIN_GET_USERS, payload: data })
export const _adminGetUsersIndicator = value => ({ type: ADMIN_GET_USERS_INDICATOR, payload: indicatorFormatter(value) })

export const _adminAddUser = data => ({ type: ADMIN_ADD_USER, payload: data })
export const _adminAddUserIndicator = value => ({ type: ADMIN_ADD_USER_INDICATOR, payload: indicatorFormatter(value) })

export const _adminGetUser = data => ({ type: ADMIN_GET_USER, payload: data })
export const _adminGetUserIndicator = value => ({ type: ADMIN_GET_USER_INDICATOR, payload: indicatorFormatter(value) })

export const _adminUpdateUser = data => ({ type: ADMIN_UPDATE_USER, payload: data })
export const _adminUpdateUserIndicator = value => ({ type: ADMIN_UPDATE_USER_INDICATOR, payload: indicatorFormatter(value) })

export const _adminDeleteUser = data => ({ type: ADMIN_DELETE_USER, payload: data })
export const _adminDeleteUserIndicator = value => ({ type: ADMIN_DELETE_USER_INDICATOR, payload: indicatorFormatter(value) })

export const adminGetUsers = (pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetUsersIndicator(true));
        return axios.get(API_SERVER + '/api/admin/users', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log("adminGetUsers success")
            console.log(response)
            dispatch(_adminGetUsersIndicator(response))
            dispatch(_adminGetUsers(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log("adminGetUsers error")
            console.log(error)
            dispatch(_adminGetUsersIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminAddUser = (payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminAddUserIndicator(true));
        return axios.post(API_SERVER + '/api/admin/users', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminAddUserIndicator(response))
            dispatch(_adminAddUser(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminAddUserIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminGetUser = (user_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetUserIndicator(true));
        return axios.get(API_SERVER + '/api/admin/users/' + user_id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminGetUserIndicator(response))
            dispatch(_adminGetUser(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminGetUserIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminUpdateUser = (user_id, payload) => {
    const cookies = new Cookies()

    var processed_ata = {
        name: payload.name,
        email: payload.email,
        admin: payload.admin,
        sites: payload.sites.map((site) => {
            return {
                site_id: site.id,
                can_edit: site.can_edit
            }
        })
    }

    return (dispatch) => {
        dispatch(_adminUpdateUserIndicator(true));
        return axios.put(API_SERVER + '/api/admin/users/' + user_id, processed_ata, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("adminUpdateUser response.data")
            // console.log(response.data)
            dispatch(_adminUpdateUserIndicator(response))
            dispatch(_adminUpdateUser(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminUpdateUserIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminDeleteUser = (user_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminDeleteUserIndicator(true));
        return axios.delete(API_SERVER + '/api/admin/users/' + user_id, {
            "headers": {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminDeleteUserIndicator(response))
            dispatch(_adminDeleteUser(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminDeleteUserIndicator(error))
            return { result: "failed", error }
        })
    }
}