export const ADMIN_GET_USERS = 'ADMIN_GET_USERS'
export const ADMIN_GET_USERS_INDICATOR =  'ADMIN_GET_USERS_INDICATOR'

export const ADMIN_ADD_USER = 'ADMIN_ADD_USER'
export const ADMIN_ADD_USER_INDICATOR = 'ADMIN_ADD_USER_INDICATOR'

export const ADMIN_GET_USER = 'ADMIN_GET_USER'
export const ADMIN_GET_USER_INDICATOR = 'ADMIN_GET_USER_INDICATOR'

export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER'
export const ADMIN_UPDATE_USER_INDICATOR = 'ADMIN_UPDATE_USER_INDICATOR'

export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER'
export const ADMIN_DELETE_USER_INDICATOR = 'ADMIN_DELETE_USER_INDICATOR'