import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './js/store'

import axios from 'axios'
import { setNeedsReAuthentication } from 'src/js/actions'

React.icons = icons

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

axios.interceptors.response.use(function (config) {
    return config
}, function (error) {
    console.log("axios.interceptors.response error")
    console.log(error.response)

    const route = error.response.config.url.split("/").pop()

    // 401 wrong creds, do not catch
    if (error.response.status === 401 && route !== "login" && route !== "logout") {
        // console.log("============================ Execute relogin")
        // console.log("axios.interceptors.response 401 error")
        // console.log(error.response)
        // dispatch(
        store.dispatch(setNeedsReAuthentication(true))
        // ).then((response) => {
        //     console.log("axios.interceptors.response inside dispatch response")
        //     console.log(response)
        // })
    }
    return Promise.reject(error)
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
