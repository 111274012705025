import {
    ADMIN_GET_SITE_ORDERS,
    ADMIN_GET_SITE_ORDERS_INDICATOR,
    // ADMIN_ADD_SITE_ORDER,
    // ADMIN_ADD_SITE_ORDER_INDICATOR,
    ADMIN_GET_SITE_ORDER,
    ADMIN_GET_SITE_ORDER_INDICATOR,
    ADMIN_UPDATE_SITE_ORDER,
    ADMIN_UPDATE_SITE_ORDER_INDICATOR,
    // ADMIN_DELETE_SITE_ORDER,
    // ADMIN_DELETE_SITE_ORDER_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    orders: [],
    getSiteOrdersIndicator: defaultIndicatorValue,
    // addedSiteOrder: {},
    // addSiteOrderIndicator: defaultIndicatorValue,
    selectedSiteOrder: {
        uuid: ""
    },
    getSiteOrderIndicator: defaultIndicatorValue,
    updatedSiteOrder: {},
    updateSiteOrderIndicator: defaultIndicatorValue,
    // deletedSiteOrder: {},
    // deleteSiteOrderIndicator: defaultIndicatorValue,
    siteOrderRegistrations: [],
    getSiteOrderRegistrationsIndicator: defaultIndicatorValue,

}

const admin_site_orders = (state = initialState, action) => {
    switch (action.type) {

        case ADMIN_GET_SITE_ORDERS:
            return { ...state, orders: action.payload }

        case ADMIN_GET_SITE_ORDERS_INDICATOR:
            return { ...state, getSiteOrdersIndicator: action.payload }

        // case ADMIN_ADD_SITE_ORDER:
        //     return { ...state, addedSiteOrder: action.payload }

        // case ADMIN_ADD_SITE_ORDER_INDICATOR:
        //     return { ...state, addSiteOrderIndicator: action.payload }

        case ADMIN_GET_SITE_ORDER:
            return { ...state, selectedSiteOrder: action.payload }

        case ADMIN_GET_SITE_ORDER_INDICATOR:
            return { ...state, getSiteOrderIndicator: action.payload }

        case ADMIN_UPDATE_SITE_ORDER:
            return { ...state, updatedSiteOrder: action.payload }

        case ADMIN_UPDATE_SITE_ORDER_INDICATOR:
            return { ...state, updateSiteOrderIndicator: action.payload }

        // case ADMIN_DELETE_SITE_ORDER:
        //     return { ...state, deletedSiteOrder: action.payload }

        // case ADMIN_DELETE_SITE_ORDER_INDICATOR:
        //     return { ...state, deleteSiteOrderIndicator: action.payload }

        default:
            return state;
    }
}

export default admin_site_orders