var initialState = {
    sidebarShow: "responsive",
}

const globals = (state = initialState, action) => {
    switch (action.type) {
        case "set":
            return { ...state, sidebarShow: action.sidebarShow }

        default:
            return state;
    }
}

export default globals