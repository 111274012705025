import { combineReducers } from 'redux'

import globals from './globals.js'
import sites from './sites.js'
import registrations from './registrations.js'
import appointments from './appointments.js'
import admin_sites from './admin_sites.js'
import admin_site_forms from './admin_site_forms.js'
import admin_site_orders from './admin_site_orders.js'
import admin_site_order_registrations from './admin_site_order_registrations.js'
import admin_site_appointment_properties from './admin_site_appointment_properties.js'
import admin_users from './admin_users.js'
import utilities from './utilities.js'
import auth from './auth.js'

const rootReducer = combineReducers({
    globals,
    sites,
    registrations,
    appointments,
    admin_sites,
    admin_site_forms,
    admin_site_orders,
    admin_site_order_registrations,
    admin_site_appointment_properties,
    admin_users,
    utilities,
    auth
});

export default rootReducer