// import Cookies from 'js-cookie';
import axios from 'axios';

import { API_SERVER } from 'src/globals';

import {
    VERIFY_PATIENT,
    VERIFY_PATIENT_INDICATOR,
    GET_SCHEDULE,
    GET_SCHEDULE_INDICATOR,
    SUBMIT_APPOINTMENT,
    SUBMIT_APPOINTMENT_INDICATOR
} from "../constants/action-types";

import { indicatorFormatter } from '../global_methods';

export const _verifyPatient = data => ({ type: VERIFY_PATIENT, payload: data })
export const _verifyPatientIndicator = value => ({ type: VERIFY_PATIENT_INDICATOR, payload: indicatorFormatter(value) })

export const _getSchedule = data => ({ type: GET_SCHEDULE, payload: data })
export const _getScheduleIndicator = value => ({ type: GET_SCHEDULE_INDICATOR, payload: indicatorFormatter(value) })

export const _submitAppointment = data => ({ type: SUBMIT_APPOINTMENT, payload: data })
export const _submitAppointmentIndicator = value => ({ type: SUBMIT_APPOINTMENT_INDICATOR, payload: indicatorFormatter(value) })

export const verifyPatient = (dob, p_id, site_id) => {
    console.log("data in actions", dob)
    return (dispatch) => {
        dispatch(_verifyPatientIndicator(true))

        return axios.get(API_SERVER + '/api/sites/' + site_id + '/appointment/validate_patient',
            {
                params: {
                    dob: dob,
                    pid: p_id,
                    //site_id: site_id
                }
            },
            {
                "headers": {
                    "Accept": "application/json",
                    // "form_token": Cookies.get('FORM_TOKEN')
                },

            }).then(response => {
                console.log(response.data)
                dispatch(_verifyPatientIndicator(response))
                dispatch(_verifyPatient(response.data))
                return { result: "success", data: response.data }
            }).catch((error) => {
                dispatch(_verifyPatient(""))
                dispatch(_verifyPatientIndicator(error))
                console.log('error ', error)


                // dispatch(_verifyPatient(true))              // Remove this
                // return {result : "success" , data: true}    //

                return { result: "failed", error }

            })
    }
}
// var scheduleData = { 
//                     "timezone":"America/New York (-4000)", 
//                     "hours": [ 
//                         { hour: "7:45 AM", slot_available: true  },
//                         { hour: "8:00 AM", slot_available: false }, 
//                         { hour: "8:15 AM", slot_available: true  }, 
//                         { hour: "8:45 AM", slot_available: false }, 
//                         { hour: "9:00 AM", slot_available: true  },
//                         { hour: "9:15 AM", slot_available: true  }
//                     ]}

export const getSchedule = (site_id, patient_id, date, service_id, timezone) => {
    console.log("data in actions")
    console.log("site_id", site_id)
    console.log("patient_id", patient_id)
    console.log("date", date)
    console.log("service_id", service_id)
    console.log("timezone", timezone)
    // console.log(API_SERVER + '/api/sites/'+ site_id +'/calendar/schedule?date=' + date + '&service_type_id=' + service_id + '&time_zone=' + timezone )
    return (dispatch) => {
        dispatch(_getScheduleIndicator(true))
        dispatch(_getSchedule(false))
        return axios.get(API_SERVER + '/api/sites/' + site_id + '/calendar/schedule?date=' + date + '&pid=' + patient_id + '&service_type_id=' + service_id + '&time_zone=' + timezone, {
            "headers": {
                "Accept": "application/json",
                // "form_token": Cookies.get('FORM_TOKEN')
            },
        }).then(response => {
            console.log("getSchedule with service_type_id")
            console.log(response.data)
            dispatch(_getScheduleIndicator(response))
            dispatch(_getSchedule(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getSchedule(false))
            dispatch(_getScheduleIndicator(error))
            console.log('error ', error)
            return { result: "failed", error }
            // dispatch(_getSchedule(scheduleData))
            // return {result : "success" ,data: scheduleData}
        })
    }
}

/** Submit Appointment */
export const submitAppointment = (site_id, data) => {
    console.log("data in actions", site_id + ' ' + data)
    return (dispatch) => {
        dispatch(_submitAppointmentIndicator(true))
        return axios.post(API_SERVER + '/api/sites/' + site_id + '/appointment', data, {
            "headers": {
                "Accept": "application/json",
                // "form_token": Cookies.get('FORM_TOKEN')
            },

        }).then(response => {
            console.log(response.data)
            dispatch(_submitAppointmentIndicator(response))
            dispatch(_submitAppointment(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log('error ', error)
            dispatch(_submitAppointmentIndicator(error))
            return { result: "failed", error }
        })
    }
}


// export const form_addNewCovidCase = (data) => {
//     //  data.mgr = parent
//     //  data.status = status
//     console.log("data in actions", data)
//     return (dispatch) => {
//         dispatch(_form_addCovidCaseNotification(true));
//         return axios.post(API_SERVER + '/api/v1/contact_case/form_close_contact', data, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             },

//         }).then(response => {
//             console.log(response.data);
//             dispatch(_form_addCovidCaseNotification(response));
//             dispatch(_form_addCovidCase(response.data));
//         }).catch((error) => {
//             dispatch(_form_addCovidCaseNotification(error));
//             console.log('error ', error);
//         })
//     }
// }

// //export const addCovidCase = ( parent,status, data ) => {
// export const form_updateCovidCase = (data) => {
//     //  data.mgr = parent
//     //  data.status = status
//     console.log("updateCovidCase", data)
//     return (dispatch) => {
//         dispatch(_form_updateCovidCaseNotification(true));
//         return axios.put(API_SERVER + '/api/v1/contact_case/update_form_data', data, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             },

//         }).then(response => {
//             console.log(response.data);
//             dispatch(_form_updateCovidCaseNotification(response));
//             dispatch(_form_updateCovidCase(response.data));
//         }).catch((error) => {
//             dispatch(_form_updateCovidCaseNotification(error));
//             console.log('error ', error);
//         })
//     }
// }



// export const form_getCloseContacts = () => {
//     return (dispatch) => {
//         dispatch(_form_setCloseContactsIndicator(true));
//         return axios.get(API_SERVER + '/api/v1/contact_case/get_form_close_contacts', {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             }
//         }).then(response => {
//             //console.log(response.data);
//             dispatch(_form_setCloseContactsIndicator(response));
//             dispatch(_form_loadCloseContacts(response.data));
//         }).catch((error) => {
//             dispatch(_form_setCloseContactsIndicator(error));
//             console.log('error ' + error);
//         })
//     }
// }

// // GET: /contact_case/get_form_data
// // GET: /contact_case/get_form_close_contacts
// // POST: /contact_case/form_close_contact
// // PUT: /contact_case/form_close_contact/{id}

// export const form_openCovidCase = () => {
//     return (dispatch) => {
//         dispatch(_form_setOpenCovidCaseNotification(true));
//         return axios.get(API_SERVER + '/api/v1/contact_case/get_form_data', {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             }
//         }).then(response => {
//             console.log(response.data);
//             dispatch(_form_setOpenCovidCaseNotification(response));
//             dispatch(_form_openCovidCase(response.data));
//         }).catch((error) => {
//             dispatch(_form_setOpenCovidCaseNotification(error));
//             console.log('error ' + error);
//         })
//     }
// }

// export const form_deleteCloseContact = (id, parent) => {
//     return (dispatch) => {
//         dispatch(_form_setDeleteCloseContactIndicator(true));
//         return axios.delete(API_SERVER + '/api/v1/contact_case/form_close_contact/' + id, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             }
//         }).then(response => {
//             console.log(response.data);
//             dispatch(_form_setDeleteCloseContactIndicator(response));
//             dispatch(form_getCloseContacts(parent));

//             // dispatch(_openCovidCase(response.data));
//         }).catch((error) => {
//             dispatch(_form_setDeleteCloseContactIndicator(error));
//             console.log('error ' + error);
//         })
//     }
// }

// export const form_editCloseContact = (id) => {
//     return (dispatch) => {
//         dispatch(_form_setEditloseContactNotification(true));
//         return axios.get(API_SERVER + '/api/v1/contact_case/form_close_contact/' + id, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             }
//         }).then(response => {
//             console.log(response.data);
//             dispatch(_form_setEditloseContactNotification(response));
//             dispatch(_form_editCloseContact(response.data));
//         }).catch((error) => {
//             dispatch(_form_setEditloseContactNotification(error));
//             console.log('error ' + error);
//         })
//     }
// }

// export const form_updateCloseContact = (data) => {
//     console.log("updateCovidCase", data)
//     return (dispatch) => {
//         dispatch(_form_setUpdateCloseContactNotification(true));
//         return axios.put(API_SERVER + '/api/v1/contact_case/form_close_contact/' + data.id, data, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             },

//         }).then(response => {
//             console.log(response.data);
//             dispatch(_form_setUpdateCloseContactNotification(response));
//             dispatch(_form_updateCloseContact(response.data));
//         }).catch((error) => {
//             dispatch(_form_setUpdateCloseContactNotification(error));
//             console.log('error ', error);
//         })
//     }
// }


