import {
    ADMIN_GET_USERS,
    ADMIN_GET_USERS_INDICATOR,
    ADMIN_ADD_USER,
    ADMIN_ADD_USER_INDICATOR,
    ADMIN_GET_USER,
    ADMIN_GET_USER_INDICATOR,
    ADMIN_UPDATE_USER,
    ADMIN_UPDATE_USER_INDICATOR,
    ADMIN_DELETE_USER,
    ADMIN_DELETE_USER_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    users: [],
    getUsersIndicator: defaultIndicatorValue,
    addedUser: {},
    addUserIndicator: defaultIndicatorValue,
    selectedUser: {},
    getUserIndicator: defaultIndicatorValue,
    updatedUser: {},
    updateUserIndicator: defaultIndicatorValue,
    deletedUser: {},
    deleteUserIndicator: defaultIndicatorValue,
}

const admin_users = (state = initialState, action) => {
    switch (action.type) {

        case ADMIN_GET_USERS:
            return { ...state, users: action.payload }

        case ADMIN_GET_USERS_INDICATOR:
            return { ...state, getUsersIndicator: action.payload }

        case ADMIN_ADD_USER:
            return { ...state, addedUser: action.payload }

        case ADMIN_ADD_USER_INDICATOR:
            return { ...state, addedUserIndicator: action.payload }

        case ADMIN_GET_USER:
            return { ...state, selectedUser: action.payload }

        case ADMIN_GET_USER_INDICATOR:
            return { ...state, getUserIndicator: action.payload }

        case ADMIN_UPDATE_USER:
            return { ...state, updatedUser: action.payload }

        case ADMIN_UPDATE_USER_INDICATOR:
            return { ...state, updateUserIndicator: action.payload }

        case ADMIN_DELETE_USER:
            return { ...state, deletedUser: action.payload }

        case ADMIN_DELETE_USER_INDICATOR:
            return { ...state, deleteUserIndicator: action.payload }

        default:
            return state;
    }
}

export default admin_users