export const ADMIN_GET_SITE_ORDERS = 'ADMIN_GET_SITE_ORDERS'
export const ADMIN_GET_SITE_ORDERS_INDICATOR =  'ADMIN_GET_SITE_ORDERS_INDICATOR'

// export const ADMIN_ADD_SITE_ORDER = 'ADMIN_ADD_SITE_ORDER'
// export const ADMIN_ADD_SITE_ORDER_INDICATOR = 'ADMIN_ADD_SITE_ORDER_INDICATOR'

export const ADMIN_GET_SITE_ORDER = 'ADMIN_GET_SITE_ORDER'
export const ADMIN_GET_SITE_ORDER_INDICATOR = 'ADMIN_GET_SITE_ORDER_INDICATOR'

export const ADMIN_UPDATE_SITE_ORDER = 'ADMIN_UPDATE_SITE_ORDER'
export const ADMIN_UPDATE_SITE_ORDER_INDICATOR = 'ADMIN_UPDATE_SITE_ORDER_INDICATOR'

// export const ADMIN_DELETE_SITE_ORDER = 'ADMIN_DELETE_SITE_ORDER'
// export const ADMIN_DELETE_SITE_ORDER_INDICATOR = 'ADMIN_DELETE_SITE_ORDER_INDICATOR'

// data
export const ADMIN_GET_SITE_ORDER_REGISTRATIONS = 'ADMIN_GET_SITE_ORDER_REGISTRATIONS'
export const ADMIN_GET_SITE_ORDER_REGISTRATIONS_INDICATOR =  'ADMIN_GET_SITE_ORDER_REGISTRATIONS_INDICATOR'
