import {
    ADMIN_GET_SITE_ORDER_REGISTRATIONS,
    ADMIN_GET_SITE_ORDER_REGISTRATIONS_INDICATOR,
    ADMIN_GET_SITE_ORDER_REGISTRATION,
    ADMIN_GET_SITE_ORDER_REGISTRATION_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    siteOrderRegistrations: [],
    getSiteOrderRegistrationsIndicator: defaultIndicatorValue,
    selectedSiteOrderRegistration: {
        files: []
    },
    getSiteOrderRegistrationIndicator: defaultIndicatorValue,

}

const admin_site_order_registrations = (state = initialState, action) => {
    switch (action.type) {

        case ADMIN_GET_SITE_ORDER_REGISTRATIONS:
            return { ...state, siteOrderRegistrations: action.payload }

        case ADMIN_GET_SITE_ORDER_REGISTRATIONS_INDICATOR:
            return { ...state, getSiteOrderRegistrationsIndicator: action.payload }

        case ADMIN_GET_SITE_ORDER_REGISTRATION:
            return { ...state, selectedSiteOrderRegistration: action.payload }

        case ADMIN_GET_SITE_ORDER_REGISTRATION_INDICATOR:
            return { ...state, getSiteOrderRegistrationIndicator: action.payload }

        default:
            return state;
    }
}

export default admin_site_order_registrations