import {
    ADMIN_GET_APPOINTMENT_PROPERTIES,
    ADMIN_GET_APPOINTMENT_PROPERTIES_INDICATOR,
    ADMIN_UPDATE_APPOINTMENT_PROPERTIES,
    ADMIN_UPDATE_APPOINTMENT_PROPERTIES_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    appointment_properties: {},
    getAppointmentPropertiesIndicator: defaultIndicatorValue,
    updatedAppointmentProperties: {},
    updateAppointmentPropertiesIndicator: defaultIndicatorValue,
}

const admin_site_appointment_properties = (state = initialState, action) => {
    switch (action.type) {

        case ADMIN_GET_APPOINTMENT_PROPERTIES:
            return { ...state, appointment_properties: action.payload }

        case ADMIN_GET_APPOINTMENT_PROPERTIES_INDICATOR:
            return { ...state, getAppointmentPropertiesIndicator: action.payload }

        case ADMIN_UPDATE_APPOINTMENT_PROPERTIES:
            return { ...state, updatedAppointmentProperties: action.payload }

        case ADMIN_UPDATE_APPOINTMENT_PROPERTIES_INDICATOR:
            return { ...state, updateAppointmentPropertiesIndicator: action.payload }

        default:
            return state;
    }
}

export default admin_site_appointment_properties