import {
    CSpinner,
    CContainer
} from '@coreui/react'
import moment from 'moment'
import momentTz from 'moment-timezone'

export const APP_NAME = "TeloPoint PDC"
export const API_SERVER = process.env.REACT_APP_API_SERVER
export const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN

export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY

export const DISABLED_TWILIO_PHONE_NUMBER_VERIFICATION_SMS = false
export const DISABLED_SMTP_VERIFICATION_EMAIL = false

// export const INLINE_LOADING = ({contClassName, msg, msgClassName, align}) => {
//     return(
//         <div className={contClassName}>
//             <CSpinner color="primary" className="d-block" />
//             <p className={msgClassName}>{msg}</p>
//         </div>
//     )
// }

export const LOADING = (
    <div className="h-100 p-5 d-flex justify-content-center align-items-center">
        <CSpinner color="primary" className="d-block" />
    </div>
)

export const SUSPENSE_FALLBACK_ELEMENT = (
    <CContainer fluid className="h-100">
        <div className="h-100 d-flex justify-content-center align-items-center">
            <div>
                {LOADING}
                <h3>Please wait...</h3>
            </div>
        </div>
    </CContainer>
)

// used for radio/dropdown
export const GENDERS = [
    { value: "male", display_name: "Male" },
    { value: "female", display_name: "Female" },
]

export const SCHOOL_JOBS = [
    { value: "student", display_name: "Student" },
    { value: "faculty_member", display_name: "Faculty Member" },
]

export const RACES = [
    { value: "", display_name: "" },
    // { value: "American Indian Or Alaskan Native", display_name: "American Indian Or Alaskan Native" },
    // { value: "Asian", display_name: "Asian" },
    // { value: "Black Or African American", display_name: "Black Or African American" },
    // { value: "Native Hawaiian Or Other Pacific Islander", display_name: "Native Hawaiian Or Other Pacific Islander" },
    // { value: "More Than One", display_name: "More Than One" },
    // { value: "Other Pacific Islander", display_name: "Other Pacific Islander" },
    // { value: "Other", display_name: "Other" },
    // { value: "Unknown/Unreported", display_name: "Unknown/Unreported" },
    // { value: "White", display_name: "White" },
    // { value: "Afro-American", display_name: "Afro-American" },
    // { value: "Native American", display_name: "Native American" },
    // { value: "Unknown", display_name: "Unknown" },
    { value: "AIAN", display_name: "American Indian Or Alaskan Native" },
    { value: "AS", display_name: "Asian" },
    { value: "BAA", display_name: "Black Or African American" },
    { value: "HAPI", display_name: "Native Hawaiian Or Other Pacific Islander" },
    { value: "MR", display_name: "More Than One" },
    { value: "OPI", display_name: "Other Pacific Islander" },
    { value: "OT", display_name: "Other" },
    { value: "UK", display_name: "Unknown/Unreported" },
    { value: "WH", display_name: "White" },
    { value: "BAA", display_name: "Afro-American" },
    { value: "AIAN", display_name: "Native American" },
    { value: "UK", display_name: "Unknown" },
]

export const ETHNICITIES = [
    { value: "", display_name: "" },
    { value: "hispanic", display_name: "Hispanic" },
    { value: "not_hispanic", display_name: "Not Hispanic" },
    { value: "unknown", display_name: "Unknown" },
]

export const PERSONAL_IDS = [
    { value: "drivers_license", display_name: "I have a driver license" },
    { value: "state_issued_non_driver", display_name: "I have a state-issued non-driver ID" },
    { value: "neither", display_name: "I have neither" },
]

export const YES_NO = [
    { value: "yes", display_name: "Yes" },
    { value: "no", display_name: "No" },
]

export const GUARANTOR_RELATIONSHIPS = [
    { value: "self", display_name: "Self" },
    { value: "other", display_name: "Other" },
]

export const default_calendar_interval = {
    "fri": false,
    "mon": false,
    "sat": false,
    "sun": false,
    "thu": false,
    "tue": false,
    "wed": false,
    "calendar_interval_times": [
        {
            "end_time": "",
            "start_time": ""
        }
    ]
}

// getConstantSpecificKeyValue(YES_NO, "value", "yes", "display_name")
export const getConstantDisplayName = (const_var, val) => {
    var return_val = "";
    const_var.forEach(el => {
        if (el.value === val) {
            return_val = el.display_name;
        }
    });

    return return_val;
}

export const getFormFieldUsingName = (arr, value) => {
    for (var i = 0, iLen = arr.length; i < iLen; i++) {
        if (arr[i].name === value) return arr[i];
    }
}

export const UtcToTzWithAbbr = ({ row, timezone }) => {
    var utc = momentTz(row.appointment_date).utcOffset(0, true)
    return utc.clone().tz(timezone).format('MM-DD-YYYY hh:mm A z')
}

export const getCurrentYear = () => {
    return moment().year()
}

export const mask = (cc, num = 4, mask = '*') => {
    return `${cc}`.slice(-num).padStart(`${cc}`.length, mask)
}