import {
    VERIFY_PATIENT,
    VERIFY_PATIENT_INDICATOR,
    GET_SCHEDULE,
    GET_SCHEDULE_INDICATOR,
    SUBMIT_APPOINTMENT,
    SUBMIT_APPOINTMENT_INDICATOR
} from "../constants/action-types";

var initialState = {
    verifyPatient: false,
    verifyPatientIndicator: false,
    getSchedule: false,
    getScheduleIndicator: false,
    submitAppointment: false,
    submitAppointmentIndicator: false
}


const appointments = (state = initialState, action) => {
    switch (action.type) {

        case VERIFY_PATIENT:
             return { ...state, verifyPatient: action.payload }

        case VERIFY_PATIENT_INDICATOR:
            return { ...state, verifyPatientIndicator: action.payload }

        case GET_SCHEDULE:
            return { ...state, getSchedule: action.payload }
   
        case GET_SCHEDULE_INDICATOR:
            return { ...state, getScheduleIndicator: action.payload }

        case SUBMIT_APPOINTMENT:
            return { ...state, submitAppointment: action.payload }
    
        case SUBMIT_APPOINTMENT_INDICATOR:
            return { ...state, submitAppointmentIndicator: action.payload }

        default:
            return state;
    }
}

export default appointments