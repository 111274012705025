import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    ADMIN_GET_SITE_ORDER_REGISTRATIONS,
    ADMIN_GET_SITE_ORDER_REGISTRATIONS_INDICATOR,
    ADMIN_GET_SITE_ORDER_REGISTRATION,
    ADMIN_GET_SITE_ORDER_REGISTRATION_INDICATOR
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _adminGetSiteOrderRegistrations = data => ({ type: ADMIN_GET_SITE_ORDER_REGISTRATIONS, payload: data })
export const _adminGetSiteOrderRegistrationsIndicator = value => ({ type: ADMIN_GET_SITE_ORDER_REGISTRATIONS_INDICATOR, payload: indicatorFormatter(value) })

export const _adminGetSiteOrderRegistration = data => ({ type: ADMIN_GET_SITE_ORDER_REGISTRATION, payload: data })
export const _adminGetSiteOrderRegistrationIndicator = value => ({ type: ADMIN_GET_SITE_ORDER_REGISTRATION_INDICATOR, payload: indicatorFormatter(value) })

export const adminGetSiteOrderRegistrations = (site_slug, order_id, pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetSiteOrderRegistrationsIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_slug + '/orders/' + order_id + '/registrations', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("adminGetSiteOrderRegistrations success")
            // console.log(response.data)
            dispatch(_adminGetSiteOrderRegistrationsIndicator(response))
            dispatch(_adminGetSiteOrderRegistrations(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log("adminGetSiteOrderRegistrations error")
            console.log(error)
            dispatch(_adminGetSiteOrderRegistrationsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminGetSiteOrderRegistration = (site_slug, order_id, order_registration_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetSiteOrderRegistrationIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_slug + '/orders/' + order_id + '/registrations/' + order_registration_id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("adminGetSiteOrderRegistration success")
            // console.log(response)
            dispatch(_adminGetSiteOrderRegistrationIndicator(response))
            dispatch(_adminGetSiteOrderRegistration(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminGetSiteOrderRegistrationIndicator(error))
            return { result: "failed", error }
        })
    }
}
