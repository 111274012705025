// import axios from 'axios'

import {
    NEEDS_RE_AUTHENTICATION,
} from "../constants/action-types"

export const _setNeedsReAuthentication = data => ({ type: NEEDS_RE_AUTHENTICATION, payload: data })

export const setNeedsReAuthentication = (payload) => {
    return (dispatch) => {
        console.log("auth setNeedsReAuthentication dispatch called")
        dispatch( _setNeedsReAuthentication(payload))
    }
}