export const VERIFY_SITE = 'VERIFY_SITE'
export const VERIFY_SITE_INDICATOR =  'VERIFY_SITE_INDICATOR'

export const GET_ALL_SITES = 'GET_ALL_SITES'
export const GET_ALL_SITES_INDICATOR =  'GET_ALL_SITES_INDICATOR'

export const ADD_SITE = 'ADD_SITE'
export const ADD_SITE_INDICATOR = 'ADD_SITE_INDICATOR'

export const GET_SITE = 'GET_SITE'
export const GET_SITE_INDICATOR = 'GET_SITE_INDICATOR'

export const UPDATE_SITE = 'UPDATE_SITE'
export const UPDATE_SITE_INDICATOR = 'UPDATE_SITE_INDICATOR'

export const DELETE_SITE = 'DELETE_SITE'
export const DELETE_SITE_INDICATOR = 'DELETE_SITE_INDICATOR'
	 	 

