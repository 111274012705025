// export const ADMIN_GET_ALL_FORMS = 'ADMIN_GET_ALL_FORMS'
// export const ADMIN_GET_ALL_FORMS_INDICATOR =  'ADMIN_GET_ALL_FORMS_INDICATOR'

// export const ADMIN_ADD_FORM = 'ADMIN_ADD_FORM'
// export const ADMIN_ADD_FORM_INDICATOR = 'ADMIN_ADD_FORM_INDICATOR'

export const ADMIN_GET_FORMS = 'ADMIN_GET_FORMS'
export const ADMIN_GET_FORMS_INDICATOR = 'ADMIN_GET_FORMS_INDICATOR'

export const ADMIN_GET_FORM = 'ADMIN_GET_FORM'
export const ADMIN_GET_FORM_INDICATOR = 'ADMIN_GET_FORM_INDICATOR'

export const ADMIN_UPDATE_FORM = 'ADMIN_UPDATE_FORM'
export const ADMIN_UPDATE_FORM_INDICATOR = 'ADMIN_UPDATE_FORM_INDICATOR'

export const ADMIN_UPDATE_FORM_HEADER_IMAGE = 'ADMIN_UPDATE_FORM_HEADER_IMAGE'
export const ADMIN_UPDATE_FORM_HEADER_IMAGE_INDICATOR = 'ADMIN_UPDATE_FORM_HEADER_IMAGE_INDICATOR'

export const ADMIN_GET_FORM_DEFAULTS = 'ADMIN_GET_FORM_DEFAULTS'
export const ADMIN_GET_FORM_DEFAULTS_INDICATOR = 'ADMIN_GET_FORM_DEFAULTS_INDICATOR'

// export const ADMIN_DELETE_FORM = 'ADMIN_DELETE_FORM'
// export const ADMIN_DELETE_FORM_INDICATOR = 'ADMIN_DELETE_FORM_INDICATOR'