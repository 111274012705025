import {
    GET_TIMEZONES,
    GET_TIMEZONES_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    timeZones: [],
    getTimeZonesIndicator: defaultIndicatorValue,
}

const utilities = (state = initialState, action) => {
    switch (action.type) {

        case GET_TIMEZONES:
            return { ...state, timeZones: action.payload }

        case GET_TIMEZONES_INDICATOR:
            return { ...state, getTimeZonesIndicator: action.payload }
        default:
            return state;
    }
}

export default utilities