import {
    ADMIN_GET_FORMS,
    ADMIN_GET_FORMS_INDICATOR,
    ADMIN_GET_FORM,
    ADMIN_GET_FORM_INDICATOR,
    ADMIN_UPDATE_FORM,
    ADMIN_UPDATE_FORM_INDICATOR,
    ADMIN_UPDATE_FORM_HEADER_IMAGE,
    ADMIN_UPDATE_FORM_HEADER_IMAGE_INDICATOR,
    ADMIN_GET_FORM_DEFAULTS,
    ADMIN_GET_FORM_DEFAULTS_INDICATOR,
    
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    forms: [],
    getFormsIndicator: defaultIndicatorValue,
    selectedForm: {},
    getFormIndicator: defaultIndicatorValue,
    updatedForm: {},
    updateFormIndicator: defaultIndicatorValue,
    updatedFormHeaderImage: null,
    updateFormHeaderImageIndicator: defaultIndicatorValue,
    formDefaults : {},
    getFormDefaults : defaultIndicatorValue

}

const admin_site_forms = (state = initialState, action) => {
    switch (action.type) {

        case ADMIN_GET_FORMS:
            return { ...state, forms: action.payload }

        case ADMIN_GET_FORMS_INDICATOR:
            return { ...state, getFormsIndicator: action.payload }

        case ADMIN_GET_FORM:
            return { ...state, selectedForm: action.payload }

        case ADMIN_GET_FORM_INDICATOR:
            return { ...state, getFormIndicator: action.payload }

        case ADMIN_UPDATE_FORM:
            return { ...state, updatedForm: action.payload }

        case ADMIN_UPDATE_FORM_INDICATOR:
            return { ...state, updateFormIndicator: action.payload }

        case ADMIN_UPDATE_FORM_HEADER_IMAGE:
            return { ...state, updatedFormHeaderImage: action.payload }

        case ADMIN_UPDATE_FORM_HEADER_IMAGE_INDICATOR:
            return { ...state, updateFormHeaderImageIndicator: action.payload }

        case ADMIN_GET_FORM_DEFAULTS:
            return { ...state, formDefaults: action.payload }

        case ADMIN_GET_FORM_DEFAULTS_INDICATOR:
            return { ...state, getFormsIndicator: action.payload }

        default:
            return state;
    }
}

export default admin_site_forms