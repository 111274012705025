import axios from 'axios'

import { API_SERVER } from 'src/globals'
// import Cookies from 'universal-cookie'

import {
    GET_TIMEZONES,
    GET_TIMEZONES_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _getTimeZones = data => ({ type: GET_TIMEZONES, payload: data })
export const _getTimeZonesIndicator = value => ({ type: GET_TIMEZONES_INDICATOR, payload: indicatorFormatter(value) })

export const getTimeZones = () => {
    // const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getTimeZonesIndicator(true));
        return axios.get(API_SERVER + '/api/utilities/timezones', {
            headers: {}
        }).then(response => {
            dispatch(_getTimeZonesIndicator(response))
            dispatch(_getTimeZones(response.data.collection))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getTimeZonesIndicator(error))
            return { result: "failed", error }
        })
    }
}