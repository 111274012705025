import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    ADMIN_GET_SITES,
    ADMIN_GET_SITES_INDICATOR,
    ADMIN_ADD_SITE,
    ADMIN_ADD_SITE_INDICATOR,
    ADMIN_GET_SITE,
    ADMIN_GET_SITE_INDICATOR,
    ADMIN_UPDATE_SITE,
    ADMIN_UPDATE_SITE_INDICATOR,
    ADMIN_DELETE_SITE,
    ADMIN_DELETE_SITE_INDICATOR,
    ADMIN_GET_SITE_REGISTRATIONS,
    ADMIN_GET_SITE_REGISTRATIONS_INDICATOR,
    ADMIN_GET_SITE_APPOINTMENTS,
    ADMIN_GET_SITE_APPOINTMENTS_INDICATOR,
    ADMIN_GET_CC_SITES,
    ADMIN_GET_CC_SITES_INDICATOR,
    ADMIN_GET_CC_SERVICE_TYPES,
    ADMIN_GET_CC_SERVICE_TYPES_INDICATOR

} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

// use for the experimental method
import {
    _adminUpdateForm,
    _adminUpdateFormIndicator,
    _adminUpdateFormHeaderImage,
    _adminUpdateFormHeaderImageIndicator,
} from './admin_site_forms'

export const _adminGetSites = data => ({ type: ADMIN_GET_SITES, payload: data })
export const _adminGetSitesIndicator = value => ({ type: ADMIN_GET_SITES_INDICATOR, payload: indicatorFormatter(value) })

export const _adminAddSite = data => ({ type: ADMIN_ADD_SITE, payload: data })
export const _adminAddSiteIndicator = value => ({ type: ADMIN_ADD_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const _adminGetSite = data => ({ type: ADMIN_GET_SITE, payload: data })
export const _adminGetSiteIndicator = value => ({ type: ADMIN_GET_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const _adminUpdateSite = data => ({ type: ADMIN_UPDATE_SITE, payload: data })
export const _adminUpdateSiteIndicator = value => ({ type: ADMIN_UPDATE_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const _adminDeleteSite = data => ({ type: ADMIN_DELETE_SITE, payload: data })
export const _adminDeleteSiteIndicator = value => ({ type: ADMIN_DELETE_SITE_INDICATOR, payload: indicatorFormatter(value) })

export const _adminGetSiteRegistrations = data => ({ type: ADMIN_GET_SITE_REGISTRATIONS, payload: data })
export const _adminGetSiteRegistrationsIndicator = value => ({ type: ADMIN_GET_SITE_REGISTRATIONS_INDICATOR, payload: indicatorFormatter(value) })

export const _adminGetSiteAppointments = data => ({ type: ADMIN_GET_SITE_APPOINTMENTS, payload: data })
export const _adminGetSiteAppointmentsIndicator = value => ({ type: ADMIN_GET_SITE_APPOINTMENTS_INDICATOR, payload: indicatorFormatter(value) })

export const _adminGetCCSites = data => ({ type: ADMIN_GET_CC_SITES, payload: data })
export const _adminGetCCSitesIndicator = data => ({ type: ADMIN_GET_CC_SITES_INDICATOR, payload: data })

export const _adminGetCCServiceTypes = data => ({ type: ADMIN_GET_CC_SERVICE_TYPES, payload: data })
export const _adminGetCCServiceTypesIndicator = data => ({ type: ADMIN_GET_CC_SERVICE_TYPES_INDICATOR, payload: data })

export const adminGetSites = (pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetSitesIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("getAllSites success")
            // console.log(response)
            dispatch(_adminGetSitesIndicator(response))
            dispatch(_adminGetSites(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("getAllSites error")
            // console.log(error)
            dispatch(_adminGetSitesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminAddSite = (payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminAddSiteIndicator(true));
        return axios.post(API_SERVER + '/api/admin/sites', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("add Site success")
            // console.log(response)
            dispatch(_adminAddSiteIndicator(response))
            dispatch(_adminAddSite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("add Site error")
            // console.log(error)
            dispatch(_adminAddSiteIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminGetSite = (site_slug) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetSiteIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_slug, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("getSite success")
            // console.log(response)
            dispatch(_adminGetSiteIndicator(response))
            dispatch(_adminGetSite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("getSite error")
            // console.log(error)
            dispatch(_adminGetSiteIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminUpdateSite = (site_slug, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminUpdateSiteIndicator(true));
        return axios.put(API_SERVER + '/api/admin/sites/' + site_slug, payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("update Site success")
            // console.log(response)
            dispatch(_adminUpdateSiteIndicator(response))
            dispatch(_adminUpdateSite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("update Site error")
            // console.log(error)
            dispatch(_adminUpdateSiteIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminDeleteSite = (site_slug) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminDeleteSiteIndicator(true));
        return axios.delete(API_SERVER + '/api/admin/sites/' + site_slug, {
            "headers": {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminDeleteSiteIndicator(response))
            dispatch(_adminDeleteSite(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminDeleteSiteIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminGetSiteRegistrations = (site_slug, pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetSiteRegistrationsIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_slug + '/registrations', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log("adminGetSiteRegistrations success")
            console.log(response.data)
            dispatch(_adminGetSiteRegistrationsIndicator(response))
            dispatch(_adminGetSiteRegistrations(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log("adminGetSiteRegistrations error")
            console.log(error)
            dispatch(_adminGetSiteRegistrationsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminGetSiteAppointments = (site_slug, pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetSiteAppointmentsIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_slug + '/appointments', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("adminGetSiteAppointments success")
            // console.log(response)
            dispatch(_adminGetSiteAppointmentsIndicator(response))
            dispatch(_adminGetSiteAppointments(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("adminGetSiteAppointments error")
            // console.log(error)
            dispatch(_adminGetSiteAppointmentsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminGetCCSites = () => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_adminGetCCSitesIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/cc', {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminGetCCSitesIndicator(response))
            dispatch(_adminGetCCSites(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminGetCCSitesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminGetCCServiceTypes = () => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminGetCCServiceTypesIndicator(true));
        return axios.get(API_SERVER + '/api/services', {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminGetCCServiceTypesIndicator(response))
            dispatch(_adminGetCCServiceTypes(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminGetCCServiceTypesIndicator(error))
            return { result: "failed", error }
        })
    }
}

// experimental

export const updateSiteMultipleInfoPromises = (
    site_slug,
    site_update,
    registration_form,
    appointment_form,
    registration_form_header_image,
    appointment_form_header_image,
) => {
    const cookies = new Cookies()
    const headers = {
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + cookies.get('user_token')
        }
    }
    const image_upload_headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer " + cookies.get('user_token')
        }
    }

    return (dispatch) => {
        dispatch(_adminUpdateSiteIndicator(true))
        dispatch(_adminUpdateFormIndicator(true))
        if (registration_form_header_image || appointment_form_header_image) {
            dispatch(_adminUpdateFormHeaderImageIndicator(true))
        }

        const updateSite = axios.put(API_SERVER + '/api/admin/sites/' + site_slug, site_update.payload, headers)
        const updateRegistrationFrom = axios.post(API_SERVER + '/api/admin/sites/' + site_slug + '/forms/' + registration_form.form_id + '?_method=PATCH', registration_form.payload, headers)
        const updateAppointmentFrom = axios.post(API_SERVER + '/api/admin/sites/' + site_slug + '/forms/' + appointment_form.form_id + '?_method=PATCH', appointment_form.payload, headers)
        var updateRegistrationFormHeaderImage = null
        var updateAppointmentFormHeaderImage = null

        if (registration_form_header_image) {
            var regFormData = new FormData()
            regFormData.append('header_image', registration_form_header_image.payload)
            updateRegistrationFormHeaderImage = axios.post(API_SERVER + '/api/admin/sites/' + site_slug + '/forms/' + registration_form_header_image.form_id + '/upload_header_image?_method=PATCH', regFormData, image_upload_headers)
        }

        if (appointment_form_header_image) {
            var aptFormData = new FormData();
            aptFormData.append('header_image', appointment_form_header_image.payload);
            updateAppointmentFormHeaderImage = axios.post(API_SERVER + '/api/admin/sites/' + site_slug + '/forms/' + appointment_form_header_image.form_id + '/upload_header_image?_method=PATCH', aptFormData, image_upload_headers)
        }

        return Promise.all([
            updateSite,
            updateRegistrationFrom,
            updateAppointmentFrom,
            updateRegistrationFormHeaderImage,
            updateAppointmentFormHeaderImage
        ]).then(axios.spread((...responses) => {
            console.log("updateSiteMultipleInfoPromises responses")
            console.log(responses)

            dispatch(_adminUpdateSiteIndicator(responses[0]))

            dispatch(_adminUpdateFormIndicator(responses[1]))
            dispatch(_adminUpdateForm(responses[1].data))

            dispatch(_adminUpdateFormIndicator(responses[2]))
            dispatch(_adminUpdateForm(responses[2].data))

            if (registration_form_header_image) {
                dispatch(_adminUpdateFormHeaderImageIndicator(responses[3]))
                dispatch(_adminUpdateFormHeaderImage(responses[3].data))
            }

            if (appointment_form_header_image) {
                dispatch(_adminUpdateFormHeaderImageIndicator(responses[4]))
                dispatch(_adminUpdateFormHeaderImage(responses[4].data))
            }

            return { result: "success", data: responses }
        })).catch((error) => {
            dispatch(_adminUpdateSiteIndicator(error))
            dispatch(_adminUpdateFormIndicator(error))
            dispatch(_adminUpdateFormHeaderImageIndicator(error))
            return { result: "failed", error }
        })
    }
}