export const ADMIN_GET_SITES = 'ADMIN_GET_SITES'
export const ADMIN_GET_SITES_INDICATOR =  'ADMIN_GET_SITES_INDICATOR'

export const ADMIN_ADD_SITE = 'ADMIN_ADD_SITE'
export const ADMIN_ADD_SITE_INDICATOR = 'ADMIN_ADD_SITE_INDICATOR'

export const ADMIN_GET_SITE = 'ADMIN_GET_SITE'
export const ADMIN_GET_SITE_INDICATOR = 'ADMIN_GET_SITE_INDICATOR'

export const ADMIN_UPDATE_SITE = 'ADMIN_UPDATE_SITE'
export const ADMIN_UPDATE_SITE_INDICATOR = 'ADMIN_UPDATE_SITE_INDICATOR'

export const ADMIN_DELETE_SITE = 'ADMIN_DELETE_SITE'
export const ADMIN_DELETE_SITE_INDICATOR = 'ADMIN_DELETE_SITE_INDICATOR'

// data
export const ADMIN_GET_SITE_REGISTRATIONS = 'ADMIN_GET_SITE_REGISTRATIONS'
export const ADMIN_GET_SITE_REGISTRATIONS_INDICATOR =  'ADMIN_GET_SITE_REGISTRATIONS_INDICATOR'

export const ADMIN_GET_SITE_APPOINTMENTS = 'ADMIN_GET_SITE_APPOINTMENTS'
export const ADMIN_GET_SITE_APPOINTMENTS_INDICATOR =  'ADMIN_GET_SITE_APPOINTMENTS_INDICATOR'

export const ADMIN_GET_CC_SERVICE_TYPES = 'ADMIN_GET_CC_SERVICE_TYPES'
export const ADMIN_GET_CC_SERVICE_TYPES_INDICATOR =  'ADMIN_GET_CC_SERVICE_TYPES_INDICATOR'

export const ADMIN_GET_CC_SITES = 'ADMIN_GET_CC_SITES'
export const ADMIN_GET_CC_SITES_INDICATOR =  'ADMIN_GET_CC_SITES_INDICATOR'
