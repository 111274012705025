import {
    NEEDS_RE_AUTHENTICATION,
} from "../constants/action-types"

var initialState = {
    needsReAuthentication: false,
}

const auth = (state = initialState, action) => {
    switch (action.type) {

        case NEEDS_RE_AUTHENTICATION:
            return { ...state, needsReAuthentication: action.payload }
            
        default:
            return state;
    }
}

export default auth