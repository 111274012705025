// import Cookies from 'js-cookie'
import axios from 'axios'

import { API_SERVER } from 'src/globals'

import {
    /** Phone */
    REQUEST_PHONE_TOKEN,
    REQUEST_PHONE_TOKEN_INDICATOR,
    VERIFY_PHONE_TOKEN,
    VERIFY_PHONE_TOKEN_INDICATOR,
    /** Email */
    REQUEST_EMAIL_TOKEN,
    REQUEST_EMAIL_TOKEN_INDICATOR,
    VERIFY_EMAIL_TOKEN,
    VERIFY_EMAIL_TOKEN_INDICATOR,

    SET_DELETED_ORDER_REGISTRATION_FILE,
    SET_DELETE_ORDER_REGISTRATION_FILE_INDICATOR,

    SUMBMIT_REGISTRATION,
    SUMBMIT_REGISTRATION_INDICATOR,
    SET_REGISTRATION_DATA,
    SET_REGISTRATION_DATA_INDICATOR
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _requestPhoneToken = data => ({ type: REQUEST_PHONE_TOKEN, payload: data })
export const _requestPhoneTokenIndicator = value => ({ type: REQUEST_PHONE_TOKEN_INDICATOR, payload: indicatorFormatter(value) })

export const _verifyPhoneToken = data => ({ type: VERIFY_PHONE_TOKEN, payload: data })
export const _verifyPhoneTokenIndicator = value => ({ type: VERIFY_PHONE_TOKEN_INDICATOR, payload: indicatorFormatter(value) })

export const _requestEmailToken = data => ({ type: REQUEST_EMAIL_TOKEN, payload: data })
export const _requestEmailTokenIndicator = value => ({ type: REQUEST_EMAIL_TOKEN_INDICATOR, payload: indicatorFormatter(value) })

export const _verifyEmailToken = data => ({ type: VERIFY_EMAIL_TOKEN, payload: data })
export const _verifyEmailTokenIndicator = value => ({ type: VERIFY_EMAIL_TOKEN_INDICATOR, payload: indicatorFormatter(value) })

export const _setRegistrationData = data => ({ type: SET_REGISTRATION_DATA, payload: data })
export const _setRegistrationDataIndicator = value => ({ type: SET_REGISTRATION_DATA_INDICATOR, payload: indicatorFormatter(value) })

export const _setDeletedOrderRegistrationFile = data => ({ type: SET_DELETED_ORDER_REGISTRATION_FILE, payload: data })
export const _setDeleteOrderRegistrationFileIndicator = value => ({ type: SET_DELETE_ORDER_REGISTRATION_FILE_INDICATOR, payload: indicatorFormatter(value) })

export const _submitRegistration = data => ({ type: SUMBMIT_REGISTRATION, payload: data })
export const _submitRegistrationIndicator = value => ({ type: SUMBMIT_REGISTRATION_INDICATOR, payload: indicatorFormatter(value) })

/** Phone verification */
export const requestPhoneToken = (phone) => {
    return (dispatch) => {
        dispatch(_requestPhoneTokenIndicator(true))
        return axios.post(API_SERVER + '/api/verifications/send_phone_code',
            {
                'phone': phone
            },
            {
                "headers": {
                    "Accept": "application/json",
                    // "form_token": Cookies.get('FORM_TOKEN')
                },

            }).then(response => {
                console.log("requestPhoneToken")
                console.log(response)
                dispatch(_requestPhoneTokenIndicator(response))
                dispatch(_requestPhoneToken(response.data))
                return { result: "success", data: response.data }
            }).catch((error) => {
                dispatch(_requestPhoneTokenIndicator(error))
                console.log('error ', error)
                return { result: "failed", error }
            })
    }
}

export const verifyPhoneToken = (phone, code) => {
    // console.log("data in actions", phone + ' ' + code)
    return (dispatch) => {
        dispatch(_verifyPhoneTokenIndicator(true))
        return axios.post(API_SERVER + '/api/verifications/verify_phone_code',
            {
                phone: phone,
                code: code
            }, {
            "headers": {
                "Accept": "application/json",
                // "form_token": Cookies.get('FORM_TOKEN')
            },

        }).then(response => {
            console.log("verifyPhoneToken")
            console.log(response)
            dispatch(_verifyPhoneTokenIndicator(response))
            dispatch(_verifyPhoneToken(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_verifyPhoneTokenIndicator(error))
            console.log('error ', error)
            return { result: "failed", error }
        })
    }
}

/** Email verification */
export const requestEmailToken = (email) => {
    return (dispatch) => {
        dispatch(_requestEmailTokenIndicator(true))
        return axios.post(API_SERVER + '/api/verifications/send_email_code',
            {
                'email': email
            },
            {
                "headers": {
                    "Accept": "application/json",
                    // "form_token": Cookies.get('FORM_TOKEN')
                },

            }).then(response => {
                console.log("requestEmailToken")
                console.log(response)
                dispatch(_requestEmailTokenIndicator(response))
                dispatch(_requestEmailToken(response.data))
                return { result: "success", data: response.data }
            }).catch((error) => {
                console.log('error ', error)
                dispatch(_requestEmailTokenIndicator(error))
                return { result: "failed", error }
            })
    }
}

export const verifyEmailToken = (email, code) => {
    // console.log("data in actions", email + ' ' + code)
    return (dispatch) => {
        dispatch(_verifyEmailTokenIndicator(true))
        return axios.post(API_SERVER + '/api/verifications/verify_email_code',
            {
                email: email,
                code: code
            }, {
            "headers": {
                "Accept": "application/json",
                // "form_token": Cookies.get('FORM_TOKEN')
            },

        }).then(response => {
            console.log("verifyEmailToken")
            console.log(response)
            dispatch(_verifyEmailTokenIndicator(response))
            dispatch(_verifyEmailToken(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log(error)
            dispatch(_verifyEmailTokenIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const deleteOrderRegistrationFile = (site_slug, file_id) => {
    return (dispatch) => {
        dispatch(_setDeleteOrderRegistrationFileIndicator(true))
        // alert("delete " + site_slug + "/" + file_id)
        return axios.delete(API_SERVER + '/api/sites/' + site_slug + '/file/' + file_id, {
            "headers": {
                "Accept": "application/json",
            }
        }).then(response => {
            dispatch(_setDeleteOrderRegistrationFileIndicator(response))
            dispatch(_setDeletedOrderRegistrationFile(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_setDeleteOrderRegistrationFileIndicator(error))
            return { result: "failed", error }
        })
    }
}

/** Submit Registration */
export const submitRegistration = (site_id, data) => {
    console.log("data in actions " + site_id)
    console.log(data)
    return (dispatch) => {
        dispatch(_submitRegistrationIndicator(true))
        return axios.post(API_SERVER + '/api/sites/' + site_id + '/registration', data, {
            "headers": {
                "Accept": "application/json",
                // "form_token": Cookies.get('FORM_TOKEN')
            },
        }).then(response => {
            console.log("submitRegistration")
            console.log(response)
            dispatch(_submitRegistrationIndicator(response))
            dispatch(_submitRegistration(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log('error ', error)
            dispatch(_submitRegistrationIndicator(error))
            return { result: "failed", error }
        })
    }
}


// //export const addCovidCase = ( parent,status, data ) => {
// export const form_updateCovidCase = (data) => {
//     //  data.mgr = parent
//     //  data.status = status
//     console.log("updateCovidCase", data)
//     return (dispatch) => {
//         dispatch(_form_updateCovidCaseNotification(true))
//         return axios.put(API_SERVER + '/api/v1/contact_case/update_form_data', data, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             },

//         }).then(response => {
//             console.log(response.data.data)
//             dispatch(_form_updateCovidCaseNotification(response))
//             dispatch(_form_updateCovidCase(response.data.data))
//         }).catch((error) => {
//             dispatch(_form_updateCovidCaseNotification(error))
//             console.log('error ', error)
//         })
//     }
// }



// export const form_getCloseContacts = () => {
//     return (dispatch) => {
//         dispatch(_form_setCloseContactsIndicator(true))
//         return axios.get(API_SERVER + '/api/v1/contact_case/get_form_close_contacts', {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             }
//         }).then(response => {
//             //console.log(response.data.data)
//             dispatch(_form_setCloseContactsIndicator(response))
//             dispatch(_form_loadCloseContacts(response.data.data))
//         }).catch((error) => {
//             dispatch(_form_setCloseContactsIndicator(error))
//             console.log('error ' + error)
//         })
//     }
// }

// // GET: /contact_case/get_form_data
// // GET: /contact_case/get_form_close_contacts
// // POST: /contact_case/form_close_contact
// // PUT: /contact_case/form_close_contact/{id}

// export const form_openCovidCase = () => {
//     return (dispatch) => {
//         dispatch(_form_setOpenCovidCaseNotification(true))
//         return axios.get(API_SERVER + '/api/v1/contact_case/get_form_data', {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             }
//         }).then(response => {
//             console.log(response.data.data)
//             dispatch(_form_setOpenCovidCaseNotification(response))
//             dispatch(_form_openCovidCase(response.data.data))
//         }).catch((error) => {
//             dispatch(_form_setOpenCovidCaseNotification(error))
//             console.log('error ' + error)
//         })
//     }
// }

// export const form_deleteCloseContact = (id, parent) => {
//     return (dispatch) => {
//         dispatch(_form_setDeleteCloseContactIndicator(true))
//         return axios.delete(API_SERVER + '/api/v1/contact_case/form_close_contact/' + id, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             }
//         }).then(response => {
//             console.log(response.data.data)
//             dispatch(_form_setDeleteCloseContactIndicator(response))
//             dispatch(form_getCloseContacts(parent))

//             // dispatch(_openCovidCase(response.data.data))
//         }).catch((error) => {
//             dispatch(_form_setDeleteCloseContactIndicator(error))
//             console.log('error ' + error)
//         })
//     }
// }

// export const form_editCloseContact = (id) => {
//     return (dispatch) => {
//         dispatch(_form_setEditloseContactNotification(true))
//         return axios.get(API_SERVER + '/api/v1/contact_case/form_close_contact/' + id, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             }
//         }).then(response => {
//             console.log(response.data.data)
//             dispatch(_form_setEditloseContactNotification(response))
//             dispatch(_form_editCloseContact(response.data.data))
//         }).catch((error) => {
//             dispatch(_form_setEditloseContactNotification(error))
//             console.log('error ' + error)
//         })
//     }
// }

// export const form_updateCloseContact = (data) => {
//     console.log("updateCovidCase", data)
//     return (dispatch) => {
//         dispatch(_form_setUpdateCloseContactNotification(true))
//         return axios.put(API_SERVER + '/api/v1/contact_case/form_close_contact/' + data.id, data, {
//             "headers": {
//                 "Accept": "application/json",
//                 "form_token": Cookies.get('FORM_TOKEN')
//             },

//         }).then(response => {
//             console.log(response.data.data)
//             dispatch(_form_setUpdateCloseContactNotification(response))
//             dispatch(_form_updateCloseContact(response.data.data))
//         }).catch((error) => {
//             dispatch(_form_setUpdateCloseContactNotification(error))
//             console.log('error ', error)
//         })
//     }
// }


