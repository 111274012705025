import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    ADMIN_GET_FORMS,
    ADMIN_GET_FORMS_INDICATOR,

    ADMIN_GET_FORM,
    ADMIN_GET_FORM_INDICATOR,

    ADMIN_UPDATE_FORM,
    ADMIN_UPDATE_FORM_INDICATOR,

    ADMIN_UPDATE_FORM_HEADER_IMAGE,
    ADMIN_UPDATE_FORM_HEADER_IMAGE_INDICATOR,

    ADMIN_GET_FORM_DEFAULTS,
    ADMIN_GET_FORM_DEFAULTS_INDICATOR
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'


export const _adminGetForms = data => ({ type: ADMIN_GET_FORMS, payload: data })
export const _adminGetFormsIndicator = value => ({ type: ADMIN_GET_FORMS_INDICATOR, payload: indicatorFormatter(value) })

export const _adminGetForm = data => ({ type: ADMIN_GET_FORM, payload: data })
export const _adminGetFormIndicator = value => ({ type: ADMIN_GET_FORM_INDICATOR, payload: indicatorFormatter(value) })

export const _adminUpdateForm = data => ({ type: ADMIN_UPDATE_FORM, payload: data })
export const _adminUpdateFormIndicator = value => ({ type: ADMIN_UPDATE_FORM_INDICATOR, payload: indicatorFormatter(value) })

export const _adminUpdateFormHeaderImage = data => ({ type: ADMIN_UPDATE_FORM_HEADER_IMAGE, payload: data })
export const _adminUpdateFormHeaderImageIndicator = value => ({ type: ADMIN_UPDATE_FORM_HEADER_IMAGE_INDICATOR, payload: indicatorFormatter(value) })


export const _adminGetFormDefaults = data => ({ type: ADMIN_GET_FORM_DEFAULTS, payload: data })
export const _adminGetFormDefaultsIndicator = value => ({ type: ADMIN_GET_FORM_DEFAULTS_INDICATOR, payload: indicatorFormatter(value) })

export const adminGetForms = (site_slug) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_adminGetFormsIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_slug + '/forms', {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminGetFormsIndicator(response))
            dispatch(_adminGetForms(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminGetFormsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminGetForm = (site_slug, form_id) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_adminGetFormIndicator(true));
        return axios.get(API_SERVER + '/api/admin/sites/' + site_slug + '/forms/' + form_id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("getForm success")
            // console.log(response)
            dispatch(_adminGetFormIndicator(response))
            dispatch(_adminGetForm(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            // console.log("getForm error")
            // console.log(error)
            dispatch(_adminGetFormIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminUpdateForm = (site_slug, form_id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_adminUpdateFormIndicator(true));
        return axios.post(API_SERVER + '/api/admin/sites/' + site_slug + '/forms/' + form_id + '?_method=PATCH', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log(response)
            dispatch(_adminUpdateFormIndicator(response))
            dispatch(_adminUpdateForm(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log(error)
            dispatch(_adminUpdateFormIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const adminUpdateFormHeaderImage = (site_slug, form_id, payload) => {
    const cookies = new Cookies()

    var formData = new FormData();
    formData.append('header_image', payload);

    return (dispatch) => {
        dispatch(_adminUpdateFormHeaderImageIndicator(true));
        return axios.post(API_SERVER + '/api/admin/sites/' + site_slug + '/forms/' + form_id + '/upload_header_image?_method=PATCH', formData, {
            headers: {
                // "Accept": "application/json",
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log(response)
            dispatch(_adminUpdateFormHeaderImageIndicator(response))
            dispatch(_adminUpdateFormHeaderImage(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log(error)
            dispatch(_adminUpdateFormHeaderImageIndicator(error))
            return { result: "failed", error }
        })
    }

}

export const adminGetFormDefaults = (form_type) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_adminGetFormDefaultsIndicator(true));
        return axios.get(API_SERVER + '/api/admin/forms/' + form_type +'/defaults', {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_adminGetFormDefaultsIndicator(response))
            dispatch(_adminGetFormDefaults(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_adminGetFormDefaultsIndicator(error))
            return { result: "failed", error }
        })
    }
}


